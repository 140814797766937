import React from 'react';
import { appName, appDescription } from "../constants/config";

const AuthLayout = ({ children }) => {
  return (
    <div
      className={'flex flex-col w-full justify-center m-auto sm:w-sm md:w-md md:flex-row lg:w-lg border-2 border-gray-800 sm:rounded-lg'}>
      <div
        className={'transition transition-all duration-300 bg-primary sm:rounded-tr-lg sm:rounded-tl-lg md:rounded-tr-none md:rounded-bl-lg flex md:w-md-1/2 lg:w-lg-1/2 md:h-md-1/2 lg:h-lg-1/2'}>
        <div className={'m-5 relative flex w-full'}>
          <div
            className={'absolute w-7 h-7 top-0 left-0 border-white border-solid border-t-2 border-l-2 md:border-t-4 md:border-l-4  md:w-10 md:h-10 lg:w-16 lg:h-16'}/>
          <div
            className={'absolute w-7 h-7 bottom-0 left-0 border-white border-solid border-b-2 border-l-2 md:border-b-4 md:border-l-4  md:w-10 md:h-10 lg:w-16 lg:h-16'}/>
          <div className={'text-center text-white w-full m-auto'}>
            <h1 className={'text-5xl font-bold mb-4'}>{appName}</h1>
            <span className={'text-lg'}>{appDescription}</span>
          </div>
        </div>
      </div>
      <div
        className={'bg-white sm:rounded-r-lg flex flex-col md:w-md-1/2 lg:w-lg-1/2 md:h-md-1/2 lg:h-lg-1/2'}>{children}</div>
    </div>
  )
}

export default AuthLayout;
