import React, { Component } from 'react';
import Login from '../user/login/Login';
import Signup from '../user/signup/Signup';
import OAuth2RedirectHandler from '../user/oauth2/OAuth2RedirectHandler';
import NotFound from '../common/NotFound';
import LoadingIndicator from '../common/LoadingIndicator';
import { getCurrentUser } from '../util/APIUtils';
import Layout from "../common/layout";
import { Redirect, Route, Switch } from "react-router";
import { allow2Oauth, allowActive, allowInvite, allowRegister } from "../constants/config";
import Active from "../user/active/Active";
import Invite from "../user/invite/Invite";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
      currentUser: null,
      loading: true
    }

    this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this);
  }

  loadCurrentlyLoggedInUser() {
    getCurrentUser()
      .then(response => {
        this.setState({
          currentUser: response,
          authenticated: true,
          loading: false
        });
      }).catch(() => {
      this.setState({
        loading: false
      });
    });
  }

  componentDidMount() {
    this.loadCurrentlyLoggedInUser();
  }

  render() {
    if (this.state.loading) {
      return <LoadingIndicator/>
    }

    return (
      <Layout>
        <Switch>
          <Route exact path="/">
            <Redirect to="/login"/>
          </Route>
          <Route path="/login" component={Login}/>
          {allowRegister && <Route path="/signup" component={Signup}/>}
          {allow2Oauth && <Route path="/oauth2/redirect" component={OAuth2RedirectHandler}/>}
          {allowActive && <Route path="/activate" component={Active}/>}
          {allowInvite && <Route path="/invite" component={Invite}/>}
          <Route component={NotFound}/>
        </Switch>
      </Layout>
    );
  }
}

export default App;
