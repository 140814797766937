import React from 'react';
import { ToastContainer } from "react-toastify";
import { LoadingLayout } from "./Loading";

const Layout = ({ children }) => {
  return (
    <LoadingLayout>
      <main className={'flex min-w-screen min-h-screen justify-center'}>{children}</main>
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={false} newestOnTop closeOnClick
                      rtl={false} pauseOnFocusLoss draggable pauseOnHover
                      theme={'colored'} limit={3}
      />
    </LoadingLayout>
  )
}

export default Layout;
