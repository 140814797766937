import { createContext, useContext } from "react";

export const LoadingContext = createContext({
  loading: false,
  toggleLoading: () => {
  },
  setLoading: (load) => {
  },
});

export const useLoadingContext = () => useContext(LoadingContext);
