export const GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL;
export const AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const ACCESS_TOKEN = 'accessToken';

export const DEFAULT_REDIRECT_URI = process.env.REACT_APP_DEFAULT_REDIRECT_URI;
export const OAUTH2_REDIRECT_URI = process.env.REACT_APP_ACCOUNT_URL + '/oauth2/redirect'

export const GOOGLE_AUTH_URL = AUTH_URL + '/api/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL = AUTH_URL + '/api/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL = AUTH_URL + '/api/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;
