import { ACCESS_TOKEN, GATEWAY_URL } from '../constants';

const request = (options) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
  })

  const defaults = { headers: headers, credentials: "include" };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options)
    .then(response =>
      response.json().then(json => {
        if (!response.ok) {
          return Promise.reject(json);
        }
        return json;
      })
    );
};

export function getCurrentUser() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: GATEWAY_URL + "/api/account",
    method: 'GET'
  });
}

export function login(loginRequest) {
  return request({
    url: GATEWAY_URL + "/api/authenticate",
    method: 'POST',
    body: JSON.stringify(loginRequest)
  });
}

export function activate(activeRequest) {
  return request({
    url: GATEWAY_URL + "/api/activate-and-change-password",
    method: 'POST',
    body: JSON.stringify(activeRequest)
  });
}

export function checkActive(key) {
  return request({
    url: GATEWAY_URL + `/api/check-active-account?key=${key}`,
    method: 'POST'
  });
}

export function signup(signupRequest) {
  return request({
    url: GATEWAY_URL + "/api/register",
    method: 'POST',
    body: JSON.stringify(signupRequest)
  });
}

export async function inviteUserData({ email, tenant_id }) {
  return request(`/api/invite`, {
    method: "POST",
    params: {
      email,
      tenant_id
    }
  })
}

export async function acceptInvite(id) {
  return request(`/api/invite/accept/${id}`, {
    method: "GET"
  })
}
