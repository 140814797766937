import React, { useContext } from 'react';
import AuthLayout from "../layout";
import { Link } from "react-router-dom";
import { login } from "../../util/APIUtils";
import { allow2Oauth, allowRegister } from "../../constants/config";
import { error } from "../../util/toast";
import { DEFAULT_REDIRECT_URI } from "../../constants";
import { useQuery } from "../../common/hooks/useQuery";
import SocialSignup from "../social-signup";
import * as yup from "yup";
import { useYupValidationForm } from "../../common/hooks/useYupValidationForm";
import { LoadingContext } from "../../common/layout/LoadingContext";

const validationSchema = yup.object({
  username: yup.string().required('Chưa nhập tài khoản'),
  password: yup.string().required('Chưa nhập mật khẩu')
});

const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useYupValidationForm(validationSchema);
  const query = useQuery();
  const loadingContext = useContext(LoadingContext)

  const onSubmit = data => {
    loadingContext.setLoading(true)
    login(data)
      .then(response => {
        const { id_token } = response;
        if (!id_token) {
          throw new Error()
        }
        const redirectParam = query.get('redirect')
        const redirect = redirectParam ?? DEFAULT_REDIRECT_URI
        const pathArr = redirect.split("/");
        const domain = pathArr.length > 3 ? `${pathArr[0]}//${pathArr[2]}` : redirect
        const stringQuery = new URLSearchParams()
        stringQuery.append('token', id_token)
        stringQuery.append('url', redirect)
        window.location.href = `${domain}/redirect?${stringQuery.toString()}`;
      }).catch(err => {
      error((err && err.detail) || 'Oops! Something went wrong. Please try again!')
    }).finally(() =>
      loadingContext.setLoading(false)
    );
  };
  return (
    <AuthLayout>
      <div className={'my-10 mx-auto md:my-8 lg:my-14'}>
        <h1 className={'text-primary font-bold text-4xl'}>Đăng nhập</h1>
      </div>
      <form className={'flex flex-col'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'mx-10 py-1 lg:py-5'}>
          <input
            className={`w-full py-2 pl-10 border-2 ${errors.username ? 'border-red-500' : 'border-gray-800'}`}
            placeholder={'Tài khoản (*)'} {...register('username', {})}/>
          <div
            className={`text-right text-red-500 font-bold mr-2 mt-1`}>
            {errors.username?.message ? errors.username.message : <>&nbsp;</>}
          </div>
        </div>
        <div className={'mx-10 py-1 lg:py-5'}>
          <input type={"password"}
                 className={`w-full py-2 pl-10 border-2 ${errors.password ? 'border-red-500' : 'border-gray-800'}`}
                 placeholder={'Mật khẩu (*)'}
                 {...register('password')}/>
          <div
            className={`text-right text-red-500 font-bold mr-2 mt-1`}>
            {errors.password?.message ? errors.password.message : <>&nbsp;</>}
          </div>
        </div>
        <div className={'mx-10'}>
          <div className={'text-right text-primary font-bold mr-2'}><span>Quên mật khẩu</span></div>
        </div>

        <div className={'mx-auto py-5 md:py-2 lg:py-5'}>
          <button className={'mx-2 text-white bg-primary py-2 w-36 rounded-2xl'} type={"submit"}>
            Đăng nhập
          </button>
          {allowRegister === true && <Link to={"/signup"}>
            <button className={'mx-2 text-primary border-2 border-primary bg-white py-2 w-36 rounded-2xl'}>
              Đăng ký
            </button>
          </Link>}
        </div>
      </form>
      {allow2Oauth === true && <>
        <div className={'lg:block md:hidden mx-auto py-2 lg:pb-4 md:py-0 flex flex-col'}>
          <span className={'block text-center'}>hoặc đăng nhập bằng</span>
        </div>
        <div className={'py-5 md:py-2'}>
          <SocialSignup redirect={query.get('redirect')}/>
        </div>
      </>}
    </AuthLayout>
  )
}

export default Login
