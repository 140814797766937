import React, { Component } from 'react';
import { Redirect } from "react-router";

class OAuth2RedirectHandler extends Component {
    getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

        var results = regex.exec(this.props.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    render() {
        const token = this.getUrlParameter('token');
        const error = this.getUrlParameter('error');

        if(token) {
            const redirect = this.getUrlParameter('redirect');
            let domain = redirect;
            const pathArr = redirect.split("/");
            if(pathArr.length > 3){
                domain = pathArr[0] + "//" + pathArr[2];
            }
            window.location.href = `${domain}/redirect?token=${token}&url=${redirect}`;
        } else {
            return <Redirect to={{
                pathname: "/",
                state: {
                    from: this.props.location,
                    error: error
                }
            }}/>;
        }
    }
}

export default OAuth2RedirectHandler;
