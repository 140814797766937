import React, { useContext, useEffect } from 'react';
import AuthLayout from "../layout";
import { activate, checkActive } from "../../util/APIUtils";
import { error } from "../../util/toast";
import { DEFAULT_REDIRECT_URI } from "../../constants";
import { useQuery } from "../../common/hooks/useQuery";
import { useYupValidationForm } from "../../common/hooks/useYupValidationForm";
import * as yup from "yup";
import { Redirect } from "react-router";
import { LoadingContext } from "../../common/layout/LoadingContext";
import { useHistory } from "react-router-dom";

const validationSchema = yup.object({
  newPassword: yup.string().min(6, 'Mật khẩu tối thiểu 6 ký tự'),
  retypePassword: yup.string().oneOf([ yup.ref('newPassword'), null ], "Mật khẩu không khớp!")
});

const Active = () => {
  const { register, handleSubmit, formState: { errors } } = useYupValidationForm(validationSchema);
  const query = useQuery();
  const key = query.get('key');
  const loadingContext = useContext(LoadingContext)
  const history = useHistory();

  useEffect(() => {
    if (key) {
      loadingContext.setLoading(true)
      checkActive(key).then().catch((err)=>{
        error((err && err.detail) || 'Key not found!')
        history.replace('/');
      }).finally(loadingContext.setLoading(false))
    }
  }, [])

  const onSubmit = data => {
    const reqData = {
      ...data,
      key
    }
    activate(reqData)
      .then(response => {
        const { id_token } = response;
        if (!id_token) {
          throw new Error()
        }
        const redirectParam = query.get('redirect')
        const redirect = redirectParam ?? DEFAULT_REDIRECT_URI
        const pathArr = redirect.split("/");
        const domain = pathArr.length > 3 ? `${pathArr[0]}//${pathArr[2]}` : redirect
        const stringQuery = new URLSearchParams()
        stringQuery.append('token', id_token)
        stringQuery.append('url', redirect)
        window.location.href = `${domain}/redirect?${stringQuery.toString()}`;
      }).catch(err => {
      error((err && err.detail) || 'Oops! Something went wrong. Please try again!')
    });
  };

  if (!key) {
    return <Redirect to={'/'}/>
  }

  return (
    <AuthLayout>
      <div className={'my-10 mx-auto md:my-8 lg:my-14'}>
        <h1 className={'text-primary font-bold text-3xl'}>Đặt lại mật khẩu</h1>
      </div>
      <form className={'flex flex-col'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'mx-10 py-1 lg:py-3'}>
          <div
            className={`font-bold mr-2 mt-1`}>
            Mật khẩu mới
          </div>
          <input type={"password"}
                 className={`w-full py-2 pl-10 border-2 ${errors.newPassword ? 'border-red-500' : 'border-gray-800'}`}
                 placeholder={'Mật khẩu mới (*)'} {...register('newPassword')}/>
          <div
            className={`text-right text-red-500 font-bold mr-2 mt-1`}>
            {errors.newPassword?.message ? errors.newPassword.message : <>&nbsp;</>}
          </div>
        </div>
        <div className={'mx-10 py-1 lg:py-3'}>
          <div
            className={`font-bold mr-2 mt-1`}>
            Đặt lại mật khẩu mới
          </div>
          <input type={"password"}
                 className={`w-full py-2 pl-10 border-2 ${errors.retypePassword ? 'border-red-500' : 'border-gray-800'}`}
                 placeholder={'Nhập lại mật khẩu mới (*)'}
                 {...register('retypePassword')}/>
          <div className={'text-right text-red-500 font-bold mr-2 mt-1'}>
            {errors.retypePassword?.message ? errors.retypePassword.message : <>&nbsp;</>}
          </div>
        </div>
        <div className={'mx-auto py-5 md:py-2 lg:py-5'}>
          <button className={'mx-2 text-white bg-primary py-2 w-36 rounded-lg'} type={"submit"}>
            Xác nhận
          </button>
        </div>
      </form>
    </AuthLayout>
  )
}

export default Active
