import React, { useContext, useEffect } from 'react';
import { useQuery } from "../../common/hooks/useQuery";
import { acceptInvite } from "../../util/APIUtils";
import { error } from "../../util/toast";
import { LoadingContext } from "../../common/layout/LoadingContext";
import { DEFAULT_REDIRECT_URI } from "../../constants";
import { useHistory } from "react-router-dom";

const Invite = () => {
  const query = useQuery();
  const key = query.get('key');
  const history = useHistory();
  const loadingContext = useContext(LoadingContext)

  useEffect(() => {
    if (key) {
      loadingContext.setLoading(true)
      acceptInvite(key)
        .then(response => {
          window.location.href = `${DEFAULT_REDIRECT_URI}`;
        }).catch(err => {
        error((err && err.detail) || 'Oops! Something went wrong. Please try again!')
      }).finally(() => {
          loadingContext.setLoading(false);
          history.replace('/');
        }
      );
    } else {
      history.replace('/')
    }

  }, [])

  return (<div/>)
}

export default Invite
