import React from 'react';
import AuthLayout from "../layout";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { signup } from "../../util/APIUtils";
import { error, success } from "../../util/toast";

const Signup = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { history } = useHistory()
  const onSubmit = data => {
    signup(data)
      .then(response => {
        success('Đăng ký thành công , vui lòng đăng nhập')
      }).catch(err => {
      error((err && err.detail) || 'Oops! Something went wrong. Please try again!')
    });
  };
  return (
    <AuthLayout>
      <div className={'mt-14 mb-10 mx-auto'}>
        <h1 className={'text-primary font-bold text-4xl'}>Đăng ký</h1>
      </div>
      <form className={'flex flex-col'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'mx-10 py-1 lg:py-5'}>
          <input required
                 className={`w-full py-2 pl-10 border-2 border-gray-800 ${errors.name ? 'border-red-500 border-2' : ''}`}
                 placeholder={'Họ và tên (*)'} {...register('name', {
            required: true,
          })}/>
          {errors.name?.type === 'required' &&
            <div className={'text-right text-red-500 font-bold mr-2 mt-1'}><span>Chưa nhập họ và tên</span></div>
          }
        </div>
        <div className={'mx-10 py-1 lg:py-5'}>
          <input required
                 className={`w-full py-2 pl-10 border-2 border-gray-800 ${errors.login ? 'border-red-500 border-2' : ''}`}
                 placeholder={'Tài khoản (*)'} {...register('login', {
            required: true,
          })}/>
          {errors.login?.type === 'required' &&
            <div className={'text-right text-red-500 font-bold mr-2 mt-1'}><span>Chưa nhập tài khoản</span></div>
          }
        </div>
        <div className={'mx-10 py-1 lg:py-5'}>
          <input required type={"password"}
                 className={`w-full py-2 pl-10 border-2 border-gray-800 ${errors.password ? 'border-red-500 border-2' : ''}`}
                 placeholder={'Mật khẩu (*)'}
                 {...register('password', {
                   required: true,
                   min: 6
                 })}/>
          {errors.password?.type === 'required' &&
            <div className={'text-right text-red-500 font-bold mr-2 mt-1'}><span>Chưa nhập mật khẩu</span></div>
          }
        </div>

        <div className={'mx-auto py-5'}>
          <Link to={"/"}>
            <button className={'mx-2 text-primary border-2 border-primary bg-white py-2 w-36 rounded-2xl'} type={'button'}>
              Đăng nhập
            </button>
          </Link>
          <button className={'mx-2 text-white bg-primary py-2 w-36 rounded-2xl'} type={'submit'}>Đăng ký</button>
        </div>
      </form>
    </AuthLayout>
  )
}

export default Signup
