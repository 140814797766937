import { toast } from "react-toastify";

export const success = (content, options = {}) => {
  return toast.success(content, {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    ...options
  });
}

export const error = (content, options = {}) => {
  return toast.error(content, {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    ...options
  });
}

export const info = (content, options = {}) => {
  return toast.info(content, {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    ...options
  });
}

export const defaultToast = (content, options = {}) => {
  return toast(content, {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    ...options
  });
}
